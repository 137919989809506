export const abi = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
			"name": "onCheckPayment",
			"inputs": [
				{"components":[{"name":"addr","type":"address"},{"name":"amount","type":"uint128"},{"name":"from","type":"address"}],"name":"payment","type":"optional(tuple)"}
			],
			"outputs": [
			]
		}
	]
}`;

export const abiBalance = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetBalance",
			"inputs": [
				{"name":"balance","type":"uint64"}
			],
			"outputs": [
			]
		}
	]
}`;

export default {
    abi,
	abiBalance
}