import axios, { AxiosPromise, Method } from 'axios';

import {
    TCard,
    IGetPrice,
    IGetCheckout,
    IGetProduct,
    IGetProducts,
    ICreateOrder,
    IGetPriceResponse,
    IGetProductResponse,
    IGetProductsResponse,
    IGetCheckoutResponse,
    ICreateOrderResponse,
} from "./../types";

const logger = console;

export class GifterClient {
  private static ApiUrl = "https://gift.surf/api";
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  constructor () {
    GifterClient.init();
  }
  
  static async init () {
    
  }


  /**
   * Get products
   **/

    getProducts (params: IGetProducts | undefined): Promise<IGetProductsResponse> {
        logger.log(`Calling getProducts...\n`);
        const config = {
            baseURL: GifterClient.ApiUrl,
            url: "/products",
            method: "get" as Method,
            params: params ? {
                user_id: params.user_id,
                order_id: params.order_id,
                category: params.category,
                country: params.country,
                offset: params.offset,
                limit: params.limit
            } : {}
        }
        return new Promise((resolve, reject) => {
            axios(config)
            .then((value) => resolve(value.data))
            .catch((error) => {
                logger.log(error);
                reject(error)
            });
        })
    }

    getProduct (params: IGetProduct | undefined): Promise<IGetProductResponse> {
        logger.log(`Calling getProduct...\n`);
        const config = {
            baseURL: GifterClient.ApiUrl,
            url: "/product",
            method: "get" as Method,
            params: params ? {
                user_id: params.user_id,
                order_id: params.order_id,
                code: params.code,
                currency_id: params.currency_id || "EVER"
            } : {}
        }
        return new Promise((resolve, reject) => {
            axios(config)
            .then((value) => resolve(value.data))
            .catch((error) => {
                logger.log(error);
                reject(error)
            });
        })
    }

    getPrice (params: IGetPrice | undefined): Promise<IGetPriceResponse> {
        logger.log(`Calling getPrice...\n`);
        const config = {
            baseURL: GifterClient.ApiUrl,
            url: "/price",
            method: "get" as Method,
            params: params ? {
                amount: params.amount,
                quantity: 1,
                promo_code: "",
                user_id: params.user_id,
                order_id: params.order_id,
                code: params.code,
                currency_id: params.currency_id || "EVER"
            } : {}
        }
        return new Promise((resolve, reject) => {
            axios(config)
            .then((value) => resolve(value.data))
            .catch((error) => {
                logger.log(error);
                reject(error)
            });
        })
    }
    
    getCheckout (params: IGetCheckout | undefined): Promise<IGetCheckoutResponse> {
        logger.log(`Calling getCheckout...\n`);
        const config = {
            baseURL: GifterClient.ApiUrl,
            url: "/checkout-product",
            method: "get" as Method,
            params: params ? {
                user_id: params.user_id,
                order_id: params.order_id,
                amount: params.amount,
                quantity: 1,
                code: params.code,
                currency_id: params.currency_id || "EVER"
            } : {}
        }
        return new Promise((resolve, reject) => {
            axios(config)
            .then((value) => resolve(value.data))
            .catch((error) => {
                logger.log(error);
                reject(error)
            });
        })
    }

    createOrder (params: ICreateOrder | undefined): Promise<ICreateOrderResponse> {
        logger.log(`Calling createOrder...\n`);
        const config = {
            baseURL: GifterClient.ApiUrl,
            url: "/checkout-product",
            method: "post" as Method,
            params: params ? {
                user_id: params.user_id,
                order_id: params.order_id,
                amount: params.amount,
                quantity: 1,
                code: params.code,
                currency_id: params.currency_id || "EVER"
            } : {}
        }
        return new Promise((resolve, reject) => {
            axios(config)
            .then((value) => resolve(value.data))
            .catch((error) => {
                logger.log(error);
                reject(error)
            });
        })
    }
}