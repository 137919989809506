import {useState, useRef, useEffect, useCallback} from 'react'
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import classnames from "classnames/bind";
import useOutsideClick from "../../utils/outsideClickCallback";
import { Connect } from '../../patterns';

const cnb = classnames.bind(styles);

export const Header = ({location, ...props}: {location: string}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [animated, setAnimated] = useState<boolean>(false);
  const refPicker = useRef<HTMLDivElement>(null);


  useOutsideClick(refPicker, () => {});

  const animateBack = (e: any) => {
    if (expanded) {
      setAnimated(true);
      setTimeout(() => setAnimated(false), 300);
    }
    e.stopPropagation();
    setExpanded(expanded ? false : true);
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      if (expanded) {
        setAnimated(true);
        setTimeout(() => setAnimated(false), 300);
      }
      setExpanded(false);
    });
    return () => {
      document.removeEventListener('click', () => {
        if (expanded) {
          setAnimated(true);
          setTimeout(() => setAnimated(false), 300);
        }
        setExpanded(false);
      });
    }
  }, [])

  return (<>
    <header className={cnb("header", {"header-centered": location === "get"})}>
      <Connect/>
      <Navbar
        expand="sm"
        expanded={expanded}
        className={cnb("navbar")}
        onToggle={(e:any) => {
          if (!e.valueOf()) {
            setAnimated(true);
            setTimeout(() => setAnimated(false), 300);
          }
        }}
      >
        <Nav className={cnb("navbar-nav", "me-auto")}>
        <Navbar.Brand className={cnb("navbar-brand")}><Link to="/" onClick={(e) => animateBack(e)} className="logo"><span>Gift <Logo/> Surf</span></Link></Navbar.Brand>
        {/* <Navbar.Toggle className={cn("navbar-toggler", "d-md-none", {"collapsed": !expanded})} aria-controls="basic-navbar-nav" onClick={(e) => animateBack(e)} />
        <Navbar.Collapse onClick={(e) => animateBack(e)} id="basic-navbar-nav" className={cn("navbar-collapse", "d-md-none", {"animated": animated})}>
          <Nav className={cn("navbar-nav", "me-auto")}>
            <NavLink className={cn("nav-link", "disabled")} to="/buy">Buy Crypto</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/sell">Sell Crypto</NavLink>
            <NavLink className={cn("nav-link")} to="/swap">Swap Crypto</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/forward">Forward Crypto</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/ramps">On- and off-ramps</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/cross-chain-transfer">Cross-chain transfer</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/nft-checkout">NFT checkout</NavLink>
            <NavLink className={cn("nav-link", "disabled")} to="/payments">Payments</NavLink>


          </Nav>
        </Navbar.Collapse> */}

        </Nav>        
      </Navbar>
    </header>
    </>
  );
};

export default Header;
