import { ReactNode } from "react";
import styles from './FormControl.module.scss';
import classnames from "classnames/bind";
const cnb = classnames.bind(styles);

export interface IFormControl {
  title?: string,
  className?: string,
  caption?: string,
  validation?: string,
  status?: "error" | "success" | "loading" | "validate" | undefined,
  children?: ReactNode,
}

export const FormControl = ({
  title,
  caption,
  status,
  className,
  children,
  ...props
} : IFormControl) => {
  return (  
    <div className={cnb("form-control", "form-control-status-" + status ,className)}>
      {title && <div className={cnb("title", "title-medium", "form-control-title")}>
        {title}
      </div>}
      {children}
      <div className={cnb("form-control-caption", "paragraph", "paragraph-tiny")}>
        {caption}
      </div>
    </div>
  );
};