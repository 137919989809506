import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from "react-router-dom";

// import { Provider } from "react-redux";
// import { store } from "./redux/store/configure-store";
import { createRoot } from 'react-dom/client';

import { RecoilRoot } from 'recoil';

import "./assets/styles/index.scss";

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HelmetProvider>
    {/* <React.StrictMode> */}
      <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    {/* </React.StrictMode> */}
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



