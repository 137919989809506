import callback from "./abi";

import { 
  Debot,
  Payment,
  address
 } from "../types";
import { Console } from "console";
//import * as browser from "debot-browser";
const browser = import("debot-browser");

export const Debots:Map<string, Debot> = new Map([
  ['papay', {
    title: 'papay',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5'
  }],
  ['balance', {
    title: 'balance',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:d1a8a4e26c36cd8c3604cd8e5ed8207ac5a2b69926b3c2fec8a9a1ae18e65899'
  }]
]);

const logger = console;

export default class DebotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DebotClient.convert('hex', 'utf8');
  static utf8ToHex = DebotClient.convert('utf8', 'hex');

  private static browserPapayHandle: Promise<BigInt>;
  private static browserBalanceHandle: Promise<BigInt>;

  constructor () {
    DebotClient.init();
  }
  
  static async init () {
    DebotClient.browserPapayHandle = (await browser).create_browser(Debots.get('papay')!.name, Debots.get('papay')!.address);
    DebotClient.browserBalanceHandle = (await browser).create_browser(Debots.get('balance')!.name, Debots.get('balance')!.address);
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  private static buildPapayManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('papay')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abi}
    }`);
  };

  private static buildBalanceManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('balance')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abiBalance}
    }`);
  };


  /**
   * Get trading pairs with trading metadata
   **/

  static async getStatus(
      keyword: string
  ): Promise<{payment: Payment}> {
    logger.log(`Calling invokeCheckPayment...\n`);
    const manifest = DebotClient.buildPapayManifest("invokeCheckPayment", `{"keyword": "${keyword}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserPapayHandle), manifest);
  }

  static async getAccountBalance(
    addr: string
  ): Promise<any> {
    logger.log(`Calling invokeGetAccountBalance...\n`);
    const manifest = DebotClient.buildBalanceManifest("invokeGetAccountBalance", `{"addr": "${addr}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserBalanceHandle), manifest);
  }

}

(async () => {
  new DebotClient();
})()

