import React, { useRef, useEffect, useState, ReactElement, ReactNode } from 'react';
import { gsap } from "gsap";
import styles from './cursorTooltip.module.scss';
import _ from "lodash";
import classnames from "classnames/bind";


export const CursorTooltip = ({
  children,
  content,
  className
}: {
  content?: ReactNode | string;
  children: ReactElement;
  className?: string
}) : JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{x: number, y: number}>({x: 0, y: 0});

  const onEnter = (e:MouseEvent) => {
    setShow(true);
    setCoordinates({
      x: e.pageX,
      y: e.pageY
    });
  }
  const onLeave = (e:MouseEvent | Event) => {
    setShow(false);
    if (ref.current)
     ref.current.setAttribute("style", '');
  }
  const onMove = _.throttle((e:MouseEvent) => {
    if (show !== true) {
      setShow(true);
      setCoordinates({
        x: e.pageX,
        y: e.pageY
      });
    }
    if (ref.current && ref.current.getAttribute("style"))
      gsap.to(ref.current, {
          duration: 0.25,
          css: {
            left: e.pageX,
            top: e.pageY
          }
        });
  }, 50);

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.addEventListener('scroll', onLeave);
  //   }

  //   return () => {
  //     if (ref.current) {
  //       ref.current.removeEventListener('scroll', onLeave);
  //     }
  //   };
  // }, []);


  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute("style", `left: ${coordinates.x}px; top: ${coordinates.y}px;`);
    }
  }, [ref.current, coordinates])

  const cn = classnames.bind(styles)

  return <>
    <div ref={ref} className={cn("tooltip", className, { "active": show && content })}>{content}</div>
    {React.cloneElement(children, {
      onMouseEnter: onEnter,
      onMouseMove: onMove,
      onMouseLeave: onLeave
    })}
  </>;
};

export default CursorTooltip;
