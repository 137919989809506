import React, { useLayoutEffect, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator, Button, Icon, FlexContainer, Flex, Toast, Dropdown, Select, IOption } from "../../components";

import { gsap } from "gsap";
import classnames from "classnames/bind";
import styles from "./Cards.module.scss";

import { TProduct } from "./../../types";

import { SwiperOptions } from 'swiper';
import { isMobile } from 'react-device-detect';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import CountriesData from "country-flag-emoji-json";
import _ from "lodash";

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
/// import 'swiper/modules/pagination/pagination.scss'; // Pagination module


// import Swiper core and required modules
// import Swiper core and required modules
import {
  Mousewheel,
  FreeMode
} from 'swiper';
import { useCards } from "../../hooks/useCards";
import { useRecoilValue } from "recoil";
import { categoriesSelector } from "../../store";
import { Link } from "react-router-dom";

const cnb = classnames.bind(styles);

const CategoriesData = [
  {
    "id": "all",
    "name": "All Categories"
  },
  {
    "name": "Beauty And Lifestyle",
    "id": "beauty-and-lifestyle"
  },
  {
    "name": "Booksellers",
    "id": "booksellers"
  },
  {
    "name": "Department Stores",
    "id": "department-stores"
  },
  {
    "name": "Electricals",
    "id": "electricals"
  },
  {
    "name": "Entertainment",
    "id": "entertainment"
  },
  {
    "name": "Experiences",
    "id": "experiences"
  },
  {
    "name": "Food And Drink",
    "id": "food-and-drink"
  },
  {
    "name": "Home And DIY",
    "id": "home-and-diy"
  },
  {
    "name": "Jewellers",
    "id": "jewellers"
  },
  {
    "name": "Leisure And Sports",
    "id": "leisure-and-sports"
  },
  {
    "name": "Mens Fashion",
    "id": "mens-fashion"
  },
  {
    "name": "Supermarkets",
    "id": "supermarkets"
  },
  {
    "name": "Toys And Games",
    "id": "toys-and-games"
  },
  {
    "name": "Travel",
    "id": "travel"
  },
  {
    "name": "Womens Fashion",
    "id": "womens-fashion"
  }
];


const Card = ({
  card,
  category,
  className
} : {
  card?: Partial<TProduct>,
  category?: string,
  className?: string,
}) => (
  <div
    className={cnb("card", {"card-mobile": isMobile}, "card-category-" + category, className)}
    // style={{backgroundImage: `url("${card?.card_image_url}")`}}
  ><Link to={`/card/${card?.code?.toLowerCase()}`}>
    <FlexContainer
      direction="row"
      justify="space-between"
      align="stretch"
      className={cnb("main-flex")}
    >
      <Flex grow={1}>
       
        <FlexContainer
          justify="flex-start"
          align="flex-start"
          direction="column"
          className={cnb("main-flex")}
        >
          <Flex
            grow={10}
            className={cnb("card-title", "action", "action-normal")}
          >
            {card?.name}
          </Flex>
          <Flex
            grow={0}
            className={cnb("card-title", "paragraph", "paragraph-normal")}
          >
          {card?.minimum_value}&nbsp;{card?.currency_code}
          </Flex>
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
        // className={cnb("card-icon")}
      >
        {/* <Icon icon="arrow-up-right-alt"/> */}
      </Flex>
    </FlexContainer></Link>
  </div>
  
);

const Slider = ({
  title,
  category,
  cards,
  className,
  selectCategory
} : {
  title?: string,
  category: string,
  cards?: Partial<TProduct>[],
  className?: string,
  selectCategory: (category: IOption) => void
}) => {
  const [size, setSize] = useState<number>(2);
  const [length, setLength] = useState<number>();
  const [groupedCards, setGroupedCards] = useState<Partial<TProduct>[][]>([]);

  function updateSize() {
    if (window.innerWidth >= 2000)
      setSize(6);
    if (window.innerWidth < 1999 && window.innerWidth >= 1600)
      setSize(5);
    if (window.innerWidth < 1599 && window.innerWidth >= 1200)
      setSize(4);
    if (window.innerWidth < 1199 && window.innerWidth >= 992)
      setSize(3);
    if (window.innerWidth < 992 && window.innerWidth >= 575)
      setSize(2);
    if (window.innerWidth < 575)
      setSize(1);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, []);

  useEffect(() => {
    const candidate = 10 + Math.round(Math.random() * 14);
    if (cards && !length) setLength(cards?.length < candidate ? cards?.length : candidate);
  }, [cards])


  useEffect(() => {
    const result: Partial<TProduct>[][] = [];

    if (cards?.length && length) {
      const module = isMobile ? 1 : size;
      cards.slice(0, length).map((card, index) => {
        if (!result[Math.floor(index / module)])
        result[Math.floor(index / module)] = [];
        result[Math.floor(index / module)][index % module] = card
      })
    }
    // if (title === "Booksellers") debugger;
    setGroupedCards(result);
  }, [size, length])
  

  return <div className={cnb("slider", className)}>
    <FlexContainer
      direction="column"
      justify="flex-start"
      align="stretch"
    >
      <Flex>
        <FlexContainer
          justify="space-between"
          align="center"
          className=""
        >
          <Flex
            grow={1}
            className="title title-large"
          >
            {title}
          </Flex>
          <Flex
            grow={0}
            className="action action-large"
          >
            <span onClick={() => selectCategory({
              value: category,
              title: category.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" "),
            })}>all {cards?.length || 0 + 1}</span>
          </Flex>
        </FlexContainer>
      </Flex>
      <Flex
        shrink={0}
      >
        
      {groupedCards && <Swiper
        className={cnb("slider")}
        slidesPerView={"auto"}
        spaceBetween={30}
        mousewheel={{
          forceToAxis: true, 
          sensitivity: 0.5
        }}
        freeMode={true}
        modules={[FreeMode, Mousewheel]}
      >

        {groupedCards.map((cards, index) => <SwiperSlide
            key={index}
            className={cnb("slider-slide", isMobile ? "slider-slide-mobile" : "slider-slide-" + cards.length)}
          >
          {cards.map((card, jindex) => <Card key={index+card?.code!+jindex} card={card} category={category} />)}
          </SwiperSlide>
        )}
      </Swiper>}
      </Flex>

    </FlexContainer>
  </div>
};


export const Cards = () => {

  const [countriesOptions, setCountriesOptions] = useState<IOption[]>([]);

  const [categorySelected, setCategorySelected] = useState<IOption>({
    title: "All categories",
    value: "all-categories"
  });
  const [countrySelected, setCountrySelected] = useState<IOption>();
  const [filtered, setFiltered] = useState<boolean | Partial<TProduct>[]>(false);

  const [productsCategorized, setProductsCategorized] = useState<{[key: string]: Partial<TProduct>[]}>({});
  // const [productsFiltered, setProductsFiltered] = useState<{[key: string]: TProduct[]}>({});

  const { products, categories, countries } = useCards();

  // const productsCategorized: {[key: string]: TProduct[]} = {}


  useEffect(() => {
    const productsCategorized: {[key: string]: Partial<TProduct>[]} = {};
    categories.map(category => productsCategorized[category] = []);
    products.map(product => product.categories?.map(category => productsCategorized[category].push(product)));
    setProductsCategorized(productsCategorized);
  }, [products, categories])

  useEffect(() => {
    setCountriesOptions([{
      icon: undefined,
      value: "all-countries",
      title: "All countries"
    }, ...countries.map((country) => {
      const countryData = CountriesData.find((item) => item.code === country);
      return {
        icon: countryData?.emoji,
        title: countryData?.name || "",
        value: countryData?.code || country
      }
    })]);
  }, [countries])

  useEffect(() => {
    setFiltered(false);
    if (categorySelected && categorySelected.value !== "all-categories" || countrySelected && countrySelected.value !== "all-countries")
      setFiltered(products
        .filter(product => 
          categorySelected && categorySelected?.value !== "all-categories" ? product.categories?.includes(categorySelected && categorySelected?.value) : true)
        .filter(product => 
          countrySelected && countrySelected?.value !== "all-countries" ? product.countries?.includes(countrySelected && countrySelected?.value) : true));
  }, [categorySelected, countrySelected, categories, countries])

  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <Row className={cnb("hero")}>
        <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <h1 className={cnb("header", "title", "title-great")}>Thousands of gift cards for your Evers’ mood</h1>
          <div className={cnb("page-description", "action", "action-normal")}>{`Simply choose from thousands of gift card \noptions from the world’s leading brands.`}</div>

          <FlexContainer
            className={cnb("flex-buttons")}
          >
            <Flex>
            <Select
              className={cnb("cards-select")}
              color="default"
              // iconLeft={{
              //   icon: <Icon icon={"qrcode"} />,
              //   animation: "left"
              // }}
              iconRight={{
                icon: <Icon icon={"chevron-down"} />,
                animation: "left"
              }}
              onSelect={setCountrySelected}
              options={countriesOptions}
              title={""}
              variant={"action"}
              placeholder={""}
              size={"medium"}
            >All countries</Select>
            </Flex>
            <Flex>
            <Select
              className={cnb("cards-select")}
              color="default"
              options={[{
                title: "All categories",
                value: "all-categories"
              }, ...categories.map(category => ({
                title: category.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" "),
                value: category
              }))]}
              selected={categorySelected}
              onSelect={setCategorySelected}
              title={""}
              placeholder={""}
              variant={"action"}
              size={"medium"}
              iconRight={{
                icon: <Icon icon={"chevron-down"} />,
                animation: "left"
              }}
            >All categories</Select>
            </Flex>
            {/* <Button size={"lg"} variant="default" disabled={true}>Coming soon</Button> */}
          </FlexContainer>
        </Col>
      </Row>
      {filtered
      ? <div className={cnb("cards-page-filtered")}
        ><>
          {filtered !== true && filtered.length 
            ? filtered.map((card, index) => <Card key={index+card?.code!} card={card} category={categorySelected && categorySelected?.value || card.categories?.[0]} />)
            : <div className={cnb("cards-page-no-results")}></div>
          }
        </>
        </div>
      : <div className={cnb("cards-page")}>
          {categories.map((category, index) => {
            return <Slider
              key={index}
              category={category}
              selectCategory={setCategorySelected}
              title={CategoriesData.find(cat => cat.id === category)?.name || category.replaceAll("-", " ")}
              cards={productsCategorized[category]}
            />
          }
          )}
        </div>
      }
    </Container>
    </>
  );
};

export default Cards;
