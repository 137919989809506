
import { useMemo } from "react";
import { GifterClient } from "../types";
import { useRecoilValue } from "recoil";
import { gifterStateAtom } from "../store";


export const useGifterClient = () => {
    const gifterState = useRecoilValue(gifterStateAtom);

    const client = useMemo(() => {
        return new GifterClient();
    }, [gifterState.config]);

    return client;
}
