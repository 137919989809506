import { useState, useEffect, useRef } from "react";

import styles from "./Graphs.module.scss";
import classnames from "classnames/bind";
import useOutsideClick from "../../utils/outsideClickCallback";
import gsap, { Power4 } from "gsap";

const cnb = classnames.bind(styles);

const myObject = {
  totalValue: 0
};

type GraphData = {
  title: string,
  amount: number
}

const Graph = ({data: {title, amount}, height}: {data: GraphData, height: number}) => {

  myObject.totalValue = 0;

  const [count, setCount] = useState(amount);

  const ref = useRef<HTMLDivElement>(null);


  useEffect(() => {
    gsap.to(myObject, {
      duration: 2,
      ease: "power4.out",
      totalValue: amount,
      roundProps: "totalValue",
      onUpdate: () => {
        setCount(myObject.totalValue);
      }
    });
  }, [amount]);

  useEffect(() => {
    if (ref.current) {
      gsap.fromTo(
        ref.current,
        {height: 0, transformOrigin: '50% 100%', duration: 1},
        {height: (90*amount/height > 25 ? 90*amount/height : 25) + '%', transformOrigin: '50% 100%', duration: 3, ease: Power4.easeOut})
    }
  }, [ref.current]);

  return (
    <div ref={ref} className={cnb("graph")}>
      <div className={cnb("title", "paragraph", "paragraph-small")}>{title}</div>
      <div className={cnb("value", "paragraph", "paragraph-large")}>{(count).toLocaleString('en')}</div>
    </div>
  );
}

export const Graphs = ({data}: {data: GraphData[]}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const refPicker = useRef<HTMLDivElement>(null);

  useOutsideClick(refPicker, () => {});

  const maxHeight = data.reduce((max: GraphData, graph: GraphData) => max.amount > graph.amount ? max : graph).amount;

  return (<div className={cnb("graphs")}>
    {data.map((graph: GraphData , index) => <Graph key={index} data={graph} height={maxHeight}/>)}
    </div>
  );
};

export default Graphs;
