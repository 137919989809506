import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";

import Main from "./pages/main";
import PaPay from "./pages/papay";
import Cards from "./pages/cards";
import Card from "./pages/card";
import Content from "./pages/content";
import { Header, Footer } from "./layouts";

const Router = () => {
  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];

  return (
    <div className={cn("ws-app", useLocation().pathname.split('/').filter(Boolean)[0], {"isMobile": isMobile, "main": !location})}>
      <Header
        location={location || "main"}
      />
      <main>
        <Routes>
          <Route path="/legal-notes/:id" element={ <Content />} />
          <Route path="/get/:id" element={<PaPay />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/card/:id" element={<Card />} />
          <Route path="/" element={<Main/>} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default Router;
