
import styles from "./Stats.module.scss";
import classnames from "classnames/bind";
import { Skeleton } from "..";

const cn = classnames.bind(styles);

export const Stats = ({ className = "", title, value, delta}: {
  className?: string,
  title: string,
  value: number,
  delta?: {
    value: number,
    units: string,
  }
}) => {
  return <div className={cn("pair-stats", className, {"up": delta && delta.value > 0, "down": delta && delta.value < 0})}>
    <div className={cn("pair-stats-header", "paragraph", "paragraph-label")}>
      {title}
    </div>
    <div className={cn("pair-stats-body", "paragraph", "paragraph-footnote")}>
      {value.toLocaleString("en", {minimumFractionDigits: 2})} &nbsp;&nbsp;{delta && <span>{delta && delta.value > 0 ? "+" : null}{delta.value.toLocaleString("en", {minimumFractionDigits: 2})}&thinsp;{delta.units}</span>}
    </div>
  </div>;
};

export const StatsSkeleton = ({ className = ""}: {
  className?: string,
}) => {
  return <div className={cn("pair-stats-skeleton", className)}>
      <div className={cn("pair-stats-header")}>
        <Skeleton wide="half"/>
      </div>
      <div className={cn("pair-stats-body")}>
        <Skeleton variant={className ==="green" ? className : "primary"} />
      </div>
  </div>;
};

export default Stats;
