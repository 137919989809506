import { Dispatch, useEffect, useMemo, useState, useRef } from "react";

import axios, { AxiosResponse } from 'axios';
import { useRecoilState } from "recoil";
import { categoriesAtom, countriesAtom, productsAtom } from "../store";
import { useGifterClient } from './useGifterClient';


import {
  IGetProducts,
  IGetProductsResponse,
  TProduct,
  TCountry,
  TCategory
 } from "../types";

export interface IUseCards {
  countries: TCountry[],
  categories: TCategory[],
  products: Partial<TProduct>[]
}

export const useCards = (params?: IGetProducts): IUseCards => {
  const GifterClient = useGifterClient();

  const [categories, setCategories] = useRecoilState(categoriesAtom);
  const [countries, setCountries] = useRecoilState(countriesAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  useEffect(() => {
    GifterClient.getProducts(params)
    .then((value:IGetProductsResponse) => {
      // console.log(value);
      if (value) {
        if(value.categories) setCategories(value.categories);
        if(value.countries) setCountries(value.countries);
        if(value.products) setProducts(value.products);
      }
    });
  }, [params]);

  return useMemo(
    () => ({
      categories,
      countries,
      products
    }),
    [categories, countries, products],
  );
};
