import React, { useState, useEffect, useRef, ReactNode } from "react";
import { Dropdown as DropdownBs, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './Dropdown.module.scss';
import { Button, IButton, Icon } from "..";
import { FormGroupProps } from "../../types";
import cn from "classnames";
import classnames from "classnames/bind";

const cnb = classnames.bind(styles);

export interface IOption {
  to?: string,
  href?: string,
  icon?: ReactNode,
  title: ReactNode,
  value: string,
  disabled?: boolean,
  default?: boolean,
  hidden?: boolean,
  className?: string,
  onClick?: () => void
}

interface IDropdown extends IButton {
  options: IOption[],
  title: string,
  placeholder: string
  active?: boolean,
  className?: string,
  optionClassName?: string,
  onSelect: (option: IOption) => void
}

interface ISelect extends IDropdown {
  selected?: IOption,
}

interface IDropdownMenu extends IButton {
  options: IOption[],
  selected?: IOption,
  optionClassName?: string,
  show?: boolean,
  className?: string,
  selectAction: (option: IOption) => void
}

function useOutsideClick(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: CustomEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", ((event: CustomEvent) => {
        handleClickOutside(event);
    }) as (event: Event) => void);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", ((event: CustomEvent) => {
            handleClickOutside(event);
        }) as (event: Event) => void);
    };
  }, [ref]);
}

export const DropdownMenu = (props: IDropdownMenu) => {
  const {
    options,
    show,
    children,
    optionClassName,
    className,
    selectAction
  } = props;

  return <div className={cnb("dropdown-menu", {"dropdown-menu-open": show})}>
    <div className={cnb("dropdown-menu-body")}>
      {options.map((option, index) => <DropdownOption key={index} {...option} onClick={(() => selectAction(option))} className={optionClassName} />)}
    </div>
  </div>
}

export const DropdownOption = (props: IOption) => {
  const {
    to,
    href,
    icon,
    title,
    value,
    disabled,
    className,
    onClick,
    hidden,
    default: isDefault
  } = props;
  
  // console.log(className);
  // if (to)
  //   return <DropdownBs.Item as={Link} to="#/action-1">{title}</DropdownBs.Item>
  // if (href)
  //   return <DropdownBs.Item as={Link} to="#/action-1">{title}</DropdownBs.Item>
  return <div onClick={onClick} className={cnb("dropdown-option", className)}>{icon ? <span>{icon}</span> : <></>}{title}</div>
}

export const Select = React.forwardRef<HTMLElement,ISelect>((props, ref) => {
  const {
    disabled,
    type,
    size = "medium",
    variant = "button",
    color = "default",
    children,
    iconLeft,
    iconRight,
    className,
    optionClassName,
    options = [],
    active: isActive,
    selected: selectedOuter,
    onSelect
  } = props;

  const __selected__ = selectedOuter ? selectedOuter : options[0]
  
  const [active, setActive] = useState<boolean>(Boolean(isActive));
  const [selected, setSelected] = useState<IOption>(__selected__);
  // const refButton = useRef<HTMLButtonElement>(null);
  const refPicker = useRef<HTMLDivElement>(null);

  const showDropdown = () => {
    setActive(!active);
  }
  const hideDropdown = () => {
    setActive(false);
  }

  const selectOption = (option: IOption) => {
    setSelected(option);
    setActive(false);
    onSelect(option);
  }

  useOutsideClick(refPicker, () => hideDropdown());

  useEffect(() => {
    if (selectedOuter)
      setSelected(selectedOuter);
  }, [selectedOuter])
  

  // useEffect(() => {
  //   if (refButton.current) {
  //     refButton.current.addEventListener("click", showDropdown);
  //   }
  //   return () => {
  //     if (refButton.current) {
  //       refButton.current.addEventListener("click", showDropdown);
  //     }
  //   }
  // }, [refButton.current])

  return <div ref={refPicker} className={cnb("dropdown", {"dropdown-open": active}, "dropdown-size-" + size, className)}>
    <Button
      disabled={disabled}
      type={type}
      size={size}
      variant={variant}
      color={color}
      iconLeft={selected && selected.icon ? {icon: <span>{selected.icon}</span>, animation: "none"} : iconLeft}
      iconRight={iconRight}
      onClick={showDropdown}
    >
      {selected ? selected.title : children}
    </Button>
    <DropdownMenu
      options={options}
      show={active}
      selectAction={selectOption}
      optionClassName={optionClassName}
    />
  </div>
});
