import { atom, selectorFamily } from "recoil";
// /import { TGoshBranch } from "../types";

export const gifterStateAtom = atom<{config: any}>({
    key: 'GifterState',
    default: {
        config: {}
    }
});

// export const goshContainers = atom<[]>({
//     key: 'GoshContainers',
//     default: []
// });

// export const goshBranchesAtom = atom<[]>({
//     key: 'GoshBranchesAtom',
//     default: []
// });

// export const goshCurrBranchSelector = selectorFamily({
//     key: 'GoshCurrBranchSelector',
//     get: (branchName: string) => ({ get }) => {
//         const branches = get(goshBranchesAtom);
//         return branches.find((branch) => branch.name === branchName);
//     }
// });