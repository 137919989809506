import { atom, selectorFamily } from "recoil";
// import { recoilPersist } from "recoil-persist";
import { TCategory, TCountry, TProduct, TOrder } from "../types";

// const { persistAtom } = recoilPersist({ key: 'recoil' });

export const categoriesAtom = atom<TCategory[]>({
    key: 'Categories',
    default: []
    // effects_UNSTABLE: [persistAtom]
});

export const countriesAtom = atom<TCountry[]>({
    key: 'Countries',
    default: []
});

export const productsAtom = atom<Partial<TProduct>[]>({
    key: 'Products',
    default: []
});
export const productAtom = atom<{
    product: TProduct,
    exchangeRate: number,
    priceCurrency: string,
    priceOffer: number,
} | undefined>({
    key: 'Product',
    default: undefined
});

export const orderAtom = atom<TOrder | undefined>({
    key: 'Order',
    default: undefined
});

export const categoriesSelector = selectorFamily({
    key: 'CategoriesFiltered',
    get: ({country, category}: {country?: string, category?: string}) => ({ get }) => {
        const products = get(productsAtom);
        return products.filter((product) => (category ? product.categories?.includes(category) : true) && (country ? product.countries?.includes(country) : true));
    }
});