import { Dispatch, useEffect, useMemo, useState, useRef } from "react";

import DebotClient from "../client/client";

import {
  Payment
 } from "../types";

export interface IUsePaPay {
  status: Payment | undefined
}

export const usePaPay = (keyword: string): IUsePaPay => {
  const [status, setStatus] = useState<Payment | undefined>(undefined)

  useEffect(() => {
    DebotClient.getStatus(keyword)
    .then((value) => {
      console.log(value);
      if (value.payment) {
        setStatus(value.payment)
      } else {
        setStatus({
          addr: "",
          from: "",
          amount: -1,
        })
      }
    });
  }, []);

  return useMemo(
    () => ({
      status
    }),
    [status],
  );
};
