import React, { useEffect, useRef, useState } from "react";

import { usePaPay } from "../../hooks";

import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";

import { Button, Loader, Skeleton } from "../../components";
import styles from './PaPay.module.scss';
import { Payment } from "../../types";

const cnb = classnames.bind(styles);

export const FastPay = ({keyword}: {keyword: string}) => {
  const {
    status
  } = usePaPay(keyword);

  const refQr = useRef<HTMLDivElement>(null);
  const [url, setUrl] = useState<string>();

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: "svg",
    margin: 0,
    qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "L"
    },
    imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0,
        margin: 0
    },
    dotsOptions: {
        type: "rounded",
        color: "#000000"
    },
    backgroundOptions: {
        color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
        type: undefined,
        color: "#000000"
    },
    cornersDotOptions: {
        type: undefined,
        color: "#000000"
    }
  });

  useEffect(() => {
    if (status && status.addr)
    setUrl(`https://uri.ever.surf/debot/0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5?manifest=${Buffer.from(buildManifest(keyword)).toString("base64").replaceAll('/+', '_-').replaceAll('=', '')}&net=mainnet`);
  }, [status])

  useEffect(() => {
    if (refQr.current && status && status.addr) {
      qrCode.update({
        data: url
      });
      if (refQr.current) {
        refQr.current.innerHTML = '';
        qrCode.append(refQr.current);
        refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 300 300");
      }
    }
    return () => {}
  }, [url]);

  const buildManifest = (
    keyword: string
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5",
    "init": {
      "method": "invokeReceivePayment",
      "params": {
        "keyword": keyword,
      }
    },
    "quiet": false,
    "chain": []
  });

  return (
    <div className={cnb("papay-widget")}>
      <div className={cnb("ticket")}>
        <div ref={refQr} className={cnb("qrcode", {"qrcode-error": status && !status.addr}, {"qrcode-loading": !status})}></div>
        <div className={cnb("body")}>
          <div className={cnb("description", "paragraph", "paragraph-medium")}> 
          {status 
            ? (status.addr
              ? "Someone wants to send you"
              : "Sorry, no tokens left here. Someone has received them already or the link can be wrong.")
            : <><Skeleton wide="half"/>&nbsp;&nbsp;<Skeleton/><br/><Skeleton/></>
          }  
          </div>
          {status && status.addr && <div className={cnb("amount", "title", "title-large")}>{(status.amount / 10**9).toLocaleString("en", {minimumFractionDigits: 9})}&nbsp;Ē</div>}
        </div>
        <div className={cnb("divider")}></div>
        {url
          ?
          <a href={url}><Button
            color="primary"
            size="large"
            disabled={!(status && status.addr)}
          >
            Get paid
          </Button></a>
          :
          <Button
            color="default"
            size="large"
            disabled={!(status && status.addr)}
          >
            Get paid
          </Button>
        }
        <div className={cnb("ticket-loader", {"ticket-loader-active": !status})}>
          <svg className={cnb("loader")} width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 15V1.00223C10 1.00136 9.99898 1.00091 9.99834 1.00148L1 9V22.9978C1 22.9986 1.00102 22.9991 1.00166 22.9985L10 15Z" fill="#4963E6" stroke="#4963E6" stroke-width="2" stroke-linejoin="round"/>
          </svg> 
        </div> 
      </div>
      <a href={"https://uri.ever.surf/debot/0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5"}>Create payment link</a>
    </div>
  );
};

export default FastPay;