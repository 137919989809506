import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator, Toast } from "../components";
import { Card } from "../views";
import { useParams } from "react-router-dom";
import { gsap } from "gsap";

const Visual = () => {
  const Layer = ({index}: {index: number}) => {
    switch (index) {
      case 0:
        return (<svg width="314" height="154" viewBox="0 0 314 154" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="10.5469" y="10.419" width="293.124" height="133.16" rx="66.5801" stroke="#4963E6" strokeWidth="20"/>
        </svg>)
      case 1:
        return (<svg width="324" height="163" viewBox="0 0 324 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.928571" x="10.0381" y="9.99993" width="302.856" height="142.761" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 2:
        return (<svg width="334" height="174" viewBox="0 0 334 174" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.8571" x="10.0751" y="9.99972" width="312.478" height="152.422" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 3:
        return (<svg width="343" height="183" viewBox="0 0 343 183" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.785714" x="10.1098" y="9.9994" width="321.986" height="162.151" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 4:
        return (<svg width="352" height="193" viewBox="0 0 352 193" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.714286" x="10.1412" y="9.999" width="331.378" height="171.953" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 5:
        return (<svg width="363" height="202" viewBox="0 0 363 202" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.642857" x="10.1683" y="9.99858" width="340.657" height="181.827" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 6:
        return (<svg width="372" height="212" viewBox="0 0 372 212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.571429" x="10.1902" y="9.99819" width="349.831" height="191.774" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 7:
        return (<svg width="381" height="223" viewBox="0 0 381 223" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.5" x="10.2064" y="9.99787" width="358.912" height="201.788" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 8:
        return (<svg width="391" height="232" viewBox="0 0 391 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.428571" x="10.2163" y="9.99766" width="367.914" height="211.862" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 9:
        return (<svg width="400" height="243" viewBox="0 0 400 243" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.357143" x="10.2196" y="9.99759" width="376.855" height="221.985" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 10:
        return (<svg width="409" height="254" viewBox="0 0 409 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.285714" x="10.2163" y="9.99766" width="385.759" height="232.144" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 11:
        return (<svg width="418" height="263" viewBox="0 0 418 263" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.214286" x="10.2064" y="9.99787" width="394.648" height="242.324" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 12:
        return (<svg width="427" height="273" viewBox="0 0 427 273" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.142857" x="10.1902" y="9.99819" width="403.549" height="252.507" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 13:
        return (<svg width="435" height="284" viewBox="0 0 435 284" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.0714286" x="10.1683" y="9.99858" width="412.487" height="262.677" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
      case 14:
        return (<svg width="444" height="294" viewBox="0 0 444 294" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.03" x="10.1412" y="9.999" width="421.491" height="272.815" rx="70" stroke="#4963E6" strokeWidth="20"/>
        </svg>);
    
      default:
        return (<svg width="314" height="154" viewBox="0 0 314 154" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="10.5469" y="10.419" width="293.124" height="133.16" rx="66.5801" stroke="#4963E6" strokeWidth="20"/>
        </svg>)
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {

    document.addEventListener("mousemove", e => {
      gsap.to(".visual-container .visual svg:not(:first-child)", {
        duration: 1,
        rotate: (index, target, targets) => {
          return - (1600 + e.clientX) * (index + 1) / 500;
        },
        scale: (index, target, targets) => {
          return 1 - (1 - e.clientY/window.screen.height) * 0.2 * (1 - ((14 - index) + 1) / 15);
        },
        // x: (index, target, targets) => {
        //   return (e.clientX/(15 - index)-window.screen.width/2)/((15 - Math.sqrt(index)));
        // }
      })
      gsap.to(".visual-container .visual svg:first-child", {
        duration: 1,
        // x: (index, target, targets) => {
        //   return (e.clientX/(15 - index)-window.screen.width/2)/((15 - Math.sqrt(index)));
        // }
      })

    });

  }, [])

  useEffect(() => {
    console.log("Render pages/card")
  }, [])
  
  
  return (<div ref={ref} className="visual">
    {[...Array(15)].map((el, index) => <Layer key={index} index={index} />)}
  </div>);
}

const Cards = ({path}: {path?: string}) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{id: string}>();
  return (
    <>
    <MetaDecorator
      title="A gift card for Evers’ mood · Gift Surf"
      description="Simply choose from thousands of gift card options from the world’s leading brands"
      keywords="ever, surf, gift, card, everscale, buy, gift cards, egift, e-gift, online"
    />
    <div className="visual-container">
      <Visual/>
    </div>

    <div className="visual-container-next">
    </div>
    {/* <Toast
        header={<div className="title title-small2">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 13.7142V6.00254C14 6.00163 13.9989 6.00119 13.9983 6.00185L10 10.2858V17.9975C10 17.9984 10.0011 17.9988 10.0017 17.9981L14 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M21 13.7142V6.00254C21 6.00163 20.9989 6.00119 20.9983 6.00185L17 10.2858V17.9975C17 17.9984 17.0011 17.9988 17.0017 17.9981L21 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M7 13.7142V6.00254C7 6.00163 6.99889 6.00119 6.99827 6.00185L3 10.2858V17.9975C3 17.9984 3.00111 17.9988 3.00173 17.9981L7 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
          </svg>Web 3.0 compatible</div>}
          
        close={true}
        variant={"dark"}
        show={toast}
        onClose={() => {setToast(false)}}
      >
        <a href="https://uri.ever.surf/debot/0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5" rel="noopener noreferrer nofollow" target="_blank">
          <div className="action action-normal">Papay Debot</div>
          <div className="parapgraph parapgraph-small">Address: 0:4a4 ···· 4ee5</div>
        </a>
      </Toast> */}

      <Card code={id?.toUpperCase()}/>
    </>
  );
};

export default Cards;
