import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator, Toast } from "../components";
import { Cards as CardsPage } from "../views";
import { useParams } from "react-router-dom";

const Cards = ({path}: {path?: string}) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{id: string}>();
  return (
    <>
    <MetaDecorator
      title="A gift card for Evers’ mood · Gift Surf"
      description="Simply choose from thousands of gift card options from the world’s leading brands"
      keywords="ever, surf, gift, card, everscale, buy, gift cards, egift, e-gift, online"
    />
    {/* <Toast
        header={<div className="title title-small2">
          <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 13.7142V6.00254C14 6.00163 13.9989 6.00119 13.9983 6.00185L10 10.2858V17.9975C10 17.9984 10.0011 17.9988 10.0017 17.9981L14 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M21 13.7142V6.00254C21 6.00163 20.9989 6.00119 20.9983 6.00185L17 10.2858V17.9975C17 17.9984 17.0011 17.9988 17.0017 17.9981L21 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M7 13.7142V6.00254C7 6.00163 6.99889 6.00119 6.99827 6.00185L3 10.2858V17.9975C3 17.9984 3.00111 17.9988 3.00173 17.9981L7 13.7142Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
          </svg>Web 3.0 compatible</div>}
          
        close={true}
        variant={"dark"}
        show={toast}
        onClose={() => {setToast(false)}}
      >
        <a href="https://uri.ever.surf/debot/0:4a4a2b20002bd599821d2b32a27d8bb4a0273247086e12c9f485376589d34ee5" rel="noopener noreferrer nofollow" target="_blank">
          <div className="action action-normal">Papay Debot</div>
          <div className="parapgraph parapgraph-small">Address: 0:4a4 ···· 4ee5</div>
        </a>
      </Toast> */}

      <CardsPage />

    </>
  );
};

export default Cards;
