import React, { useLayoutEffect, useRef, useState, useEffect, ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MetaDecorator, Button, Icon, FlexContainer, Flex, Loader, Overlay, Select, Skeleton, Input, LoaderDotsText } from "../../components";

import { gsap } from "gsap";
import classnames from "classnames/bind";
import styles from "../Cards/Cards.module.scss";

import { TProduct } from "../../types";

import { SwiperOptions } from 'swiper';
import { isMobile } from 'react-device-detect';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react/swiper-react';

import { Swiper as SwiperClass } from 'swiper/types';
import CountriesData from "country-flag-emoji-json";
import _ from "lodash";

import QRCodeStyling from "qr-code-styling";
import { hash } from "../../utils";


// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import "swiper/modules/effect-fade/effect-fade.scss";

/// import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// import Swiper core and required modules
import {
  EffectFade,
  Mousewheel,
  FreeMode
} from 'swiper';


import { useCard } from "../../hooks/useCard";
import { useCards } from "../../hooks/useCards";
import { useRecoilValue } from "recoil";
import { categoriesSelector } from "../../store";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";

import * as Yup from 'yup';

const cnb = classnames.bind(styles);

const SlideWrapper = ({direction, disabled, children}: {direction: "next" | "prev" | number, disabled?: boolean, children: ReactNode}) => {
  const swiper = useSwiper();
  return (
    <div className={cnb("swiper-button-wrapper")} onClick={() => {
      if (!disabled)
        switch (direction) {
          case "prev":
            swiper.slidePrev()
            break;
          case "next":
            swiper.slideNext()
            break;
          default:
            swiper.slideTo(Number(direction))
            break;
        }
    }}>{children}</div>
  );
}

export const Card = ({
  code,
  className
} : {
  code?: string,
  className?: string,
}) => { 

  const useCardParams = {
    code: code,
    currency_id: "ever"
  };
  const { products, exchangeRate, priceCurrency, priceOffer, product, similarProducts, getPrice, getCheckout, createOrder } = useCard(useCardParams);

  const [cardData, setCardData] = useState<Partial<TProduct>>();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<SwiperClass>(useSwiper());
  const [priceTimeout, setPriceTimeout] = useState<NodeJS.Timeout>();
  const [buttonReviewLoading, setButtonReviewLoading] = useState<boolean>(false);
  const [buttonCreateLoading, setButtonCreateLoading] = useState<boolean>(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);


  interface FormValues {
    amount: number | undefined,
    email: string,
    message: string
  }

  const refQr = useRef<HTMLDivElement>(null);

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: "svg",
    margin: 0,
    qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "L"
    },
    imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0,
        margin: 0
    },
    dotsOptions: {
        type: "rounded",
        color: "#20262A"
    },
    backgroundOptions: {
        color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
        type: undefined,
        color: "#20262A"
    },
    cornersDotOptions: {
        type: undefined,
        color: "#20262A"
    }
  });

  const [url, setUrl] = useState<string>();

  const WALLET_ADDRESS = "0:fb69ca9f22e9ce54d926176ca68614644521050c77dc53d644081c51ef3a892c";

  useEffect(() => {
    if (priceOffer)
      setUrl(`https://uri.ever.surf/transfer/${WALLET_ADDRESS}?amount=${priceOffer*(10**9)}&text=${Buffer.from("id").toString("base64")}`);
  }, [priceOffer])

  useEffect(() => {
    console.log(url);
    if (refQr.current) {
      qrCode.update({
        data: url
      });
      refQr.current.innerHTML = '';
      qrCode.append(refQr.current);
      if (refQr.current.querySelector("svg")) refQr.current.querySelector("svg")!.setAttribute("viewBox", "0 0 300 300");
    }
  }, [url, openModal]);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Nice try😄')
      .required('')
      .positive('Nice try😄')
      .min(product?.minimum_value || 0, 'Minimum ' + (product?.minimum_value || 0))
      .max(product?.maximum_value || Number.MAX_SAFE_INTEGER, 'Maximum ' + (product?.maximum_value || Number.MAX_SAFE_INTEGER)),
    message: Yup.string()
      .typeError('Nice try😄'),
    email: Yup.string()
      .email("That's not an email")
      .typeError('Nice try😄')
      .required(''),
    wallet: Yup.string()
      .matches(/^0:[a-f0-9]{64,64}$/, "Doesn't look like a valid Surf wallet ID")
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: product?.minimum_value || undefined,
      email: "",
      message: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {
    }
  });

  useEffect(() => {
    return () => {
      console.log("amount: undefined");
      formik.setFieldValue("amount", undefined);
      setLoaded(false);
    }
  }, []);

  useEffect(() => {
    if (code)
      setCardData(products.find(product => product.code?.toLowerCase() === code.toLowerCase()))
  }, [products, code]);

  useEffect(() => {
    if (cardData?.name || product?.name)
      setLoaded(true);
      formik.setFieldValue("amount", product?.minimum_value ? Number(product?.minimum_value) : undefined);
    return () => {
    }
  }, [product, cardData]);

  useEffect(() => {
    setUpdated(false);
    if (priceTimeout)
      clearTimeout(priceTimeout);
    setPriceTimeout(setTimeout(() => {
      if (product)
        getPrice({
          currency_id: "ever",
          code: code,
          amount: formik.values.amount,
          quantity: 1,
        },
        () => {
          setUpdated(true);
        });
    }, 1000));
  }, [formik.values.amount]);

  async function handleRewievOrder () {
    setButtonReviewLoading(true);
    getCheckout({
      user_id: formik.values.email,
      order_id: formik.values.email + (await hash(formik.values.message)).substring(0, 16),
      currency_id: "ever",
      code: code,
      amount: formik.values.amount,
      quantity: 1,
    },
    () => {
      swiper.slideNext();
      setButtonReviewLoading(false);
    });
  }

  async function handleCreateOrder () {
    setButtonCreateLoading(true);
    createOrder({
      user_id: formik.values.email,
      order_id: formik.values.email + (await hash(formik.values.message)).substring(0, 16),
      currency_id: "ever",
      code: code,
      amount: formik.values.amount,
      quantity: 1,
    },
    () => {
      setOpenModal(true);
      setButtonCreateLoading(false);
    });
  }

  return (<>
    <Overlay
      show={openModal}
      body={
       
      <Container fluid className={cnb("content-container")}>
        <FlexContainer
          justify="space-between"
          align="flex-start"
          direction="row"
        >
          <Flex>
          </Flex>
          <Flex>
            <Button
              size="large"
              color="default"
              onClick={() => {
                setOpenModal(false);
              }}
              iconLeft={{
                icon: <Icon icon="close"/>,
                animation: "none"
              }}
            ></Button>
          </Flex>
        </FlexContainer>
        <FlexContainer
            justify="flex-start"
            align="stretch"
            direction="column"
            className={cnb("card-page")}
          >
            <Flex
              grow={0}
              className={cnb("card-page-title", "action", "action-normal")}
            >
              <h3>Payment</h3>
            </Flex>
            <Flex
              grow={1}
            >
              <FlexContainer
                justify="center"
                align="center"
                direction="column"
              >
                <div ref={refQr} className={cnb("qrcode")}></div>

                <div className={cnb("qrcode-desc", "paragraph", "paragraph-small", "align-center")}>{`Scan this QR code or follow the everlink to make a safe payment`}</div>
                  
                <a href={url} className={cnb("qrcode-link", "paragraph", "paragraph-medium", "align-center")}>Open Everlink</a>

              </FlexContainer>
            </Flex>
            <Flex>
              <Button
                className={cnb("card-page-cta")}
                type="button"
                variant="button"
                color="default"
                size="large"
                style={{}}
                // iconRight={{
                //   animation: "none",
                //   icon: <Icon icon="arrow-right" />
                // }}
              >Done</Button>
            </Flex>
          </FlexContainer>
      </Container> 
      }
    >
    </Overlay>
    <Container fluid className={cnb("content-container")}>
      <FlexContainer
        justify="space-between"
        align="flex-start"
        direction="row"
      >
        <Flex>
          {activeSlide
          ? 
            <Button
              size="large"
              color="default"
              iconLeft={{
                icon: <Icon icon="arrow-left"/>,
                animation: "none"
              }}
              onClick={() => swiper.slidePrev()}
            ></Button>
          : <Link to="/cards"><Button
              size="large"
              color="default"
              iconLeft={{
                icon: <Icon icon="arrow-left"/>,
                animation: "none"
              }}
            ></Button></Link>
          }
        </Flex>
        <Flex>
          {/* <Link to="/cards"><Button
            size="large"
            color="default"
            iconLeft={{
              icon: <Icon icon="close"/>,
              animation: "none"
            }}
          ></Button></Link> */}
        </Flex>
      </FlexContainer>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}></form>
      <Swiper
        slidesPerView={1}
        allowTouchMove={false}
        navigation={false}
        effect={"fade"}
        modules={[EffectFade]}
        fadeEffect={{ crossFade: true }}
        speed={300}
        
        pagination={false}
        scrollbar={false}
        spaceBetween={3000}
        onSwiper={(swiper) => {setSwiper(swiper)}}
        onSlideChange={(swiper) => {
          setActiveSlide(swiper.activeIndex)
        }}
        className={cnb("card-page")}
      >
        <SwiperSlide
          className={cnb("card-page-slider-slide")}
        >
          <FlexContainer
            justify="flex-start"
            align="stretch"
            direction="column"
          >
            <Flex
              grow={0}
              className={cnb("card-page-title", "action", "action-normal")}
            >
              <h3>{loaded ? product?.name || cardData?.name : <><Skeleton variant="primary" size="large" /> <Skeleton variant="primary" size="large" wide="half" /> </>}</h3>
              <div className={cnb("card-page-description", "paragraph", "paragraph-normal", {"card-page-description-active": descriptionExpanded})}>{loaded ? <>{product?.description}</> || cardData?.description : <><Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" wide="half" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" wide="half" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" /> <Skeleton variant="normal" size="normal" wide="half" /> </>}</div>
              {!descriptionExpanded && loaded && <a 
                onClick={(e) => {
                  e.preventDefault();
                  setDescriptionExpanded(true);
                }}
              className={cnb("more-button")}>more</a>}
            </Flex>
            <Flex
              grow={0}
              className={cnb("card", {"card-loading": !loaded}, {"card-mobile": isMobile}, "card-category-" + "category", className)}
            >
              <FlexContainer
                direction="column"
                justify="flex-end"
                align="stretch"
                className={cnb("main-flex")}
              >
                <Flex
                  grow={0}
                  className={cnb("card-title", "paragraph", "paragraph-normal")}
                >
                  {loaded 
                    ? <>{formik.values.amount}&nbsp;{product?.currency_code || cardData?.currency_code}</>
                    : <Skeleton variant="primary" size="normal" wide="half" />}
                </Flex>
                <Flex
                  grow={0}
                  className={cnb("card-title", "paragraph", "paragraph-small")}
                >
                {loaded 
                  ? <>{updated ? priceOffer : <LoaderDotsText/>}&nbsp;{priceCurrency?.toUpperCase()}</>
                  : <Skeleton variant="normal" size="normal" />}
                </Flex>
              </FlexContainer>
            </Flex>
            <Flex>
              {product?.denomination_type === "fixed"
              ?   
                <Select
                  className={cnb("card-page-select")}
                  color="default"
                  // iconLeft={{
                  //   icon: <Icon icon={"qrcode"} />,
                  //   animation: "left"
                  // }}
                  iconRight={{
                    icon: <Icon icon={"chevron-down"} />,
                    animation: "left"
                  }}
                  onSelect={(e) => {
                    formik.setFieldValue("amount", parseFloat(e.value))
                  }}
                  options={product?.available_denominations.map((denom) => ({
                    icon: undefined,
                    value: denom,
                    title: denom + " EVER"
                  }))}
                  title={""}
                  variant={"action"}
                  placeholder={""}
                  size={"large"}
                >{product?.available_denominations[0]} EVER</Select>
              :
                <Input
                  className={cnb("card-page-input")}
                  name={"amount"}
                  value={0}
                  label={"Amount"}
                  placeholder={"Amount"}
                  composition={"substitute"}
                  caption={loaded ? `min. ${product?.minimum_value} — ${product?.maximum_value} max.` : "min — max"}
                  onChange={(e) => {}}
                />
              }
              <SlideWrapper
                direction="next"
                disabled={!formik.values.amount || Boolean(formik.errors.amount)}
              >
              <Button
                className={cnb("card-page-cta")}
                type="button"
                variant="action"
                color="primary"
                size="large"
                disabled={!formik.values.amount || Boolean(formik.errors.amount)}
                iconRight={{
                  animation: "none",
                  icon: <Icon icon="arrow-right" />
                }}
              >Send gift</Button></SlideWrapper>
            </Flex>
          </FlexContainer>
        </SwiperSlide>
        <SwiperSlide
            className={cnb("card-page-slider-slide")}
        >
          <FlexContainer
            justify="flex-start"
            align="stretch"
            direction="column"
          >
            <Flex
              grow={10}
              className={cnb("card-page-title", "action", "action-normal")}
            >
              <h3>Recipient</h3>
            </Flex>
            <Flex>

              <SlideWrapper
                direction="prev"
              >
                <FlexContainer
                  justify="flex-start"
                  align="center"
                  direction="row"
                  className={cnb("card-small")}
                >
                  <Flex
                    grow={10}
                    className={cnb("card-title", "action", "action-normal")}
                  >
                    {cardData?.name || product?.name}
                  </Flex>
                  <Flex
                    grow={0}
                    className={cnb("card-title", "paragraph", "paragraph-normal")}
                  >
                    {formik.values.amount}&nbsp;{cardData?.currency_code || product?.currency_code}
                  </Flex>
                </FlexContainer>
              </SlideWrapper>
              <Input
                className={cnb("card-page-input")}
                name={"email"}
                value={0}
                label={"Amount"}
                placeholder={"Recipient email"}
                composition={"substitute"}
                onChange={() => {}}
              />
              <Input
                className={cnb("card-page-input")}
                name={"message"}
                value={0}
                label={"Amount"}
                placeholder={"Short message"}
                composition={"substitute"}
                onChange={() => {}}
              />

              <Button
                className={cnb("card-page-cta")}
                type="button"
                variant="action"
                color="primary"
                size="large"
                iconRight={{
                  animation: "none",
                  icon: buttonReviewLoading ? <Loader className={cnb("button-loader")} /> : <Icon icon="arrow-right" />
                }}
                disabled={!Boolean(formik.values.email)}
                onClick={handleRewievOrder}
              >Review order</Button>
            </Flex>
          </FlexContainer>
        </SwiperSlide>

        <SwiperSlide
            className={cnb("card-page-slider-slide")}
        >
          <FlexContainer
            justify="flex-start"
            align="stretch"
            direction="column"
          >
            <Flex
              grow={10}
              className={cnb("card-page-title", "action", "action-normal")}
            >
              <h3>Order</h3>
            </Flex>
            <Flex>
              <dl className={cnb("card-summary", "paragraph", "paragraph-normal")}>
                <FlexContainer
                  className={cnb("card-summary-row")}
                  justify="flex-start"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Subject</dt><dd>{formik.values.message}</dd>
                </FlexContainer>
                <FlexContainer
                  className={cnb("card-summary-row")}
                  justify="flex-start"
                >
                  <dt className={cnb("color-faded", "paragraph")}>To</dt><dd>{formik.values.email}</dd>
                </FlexContainer>
              </dl>

              <SlideWrapper
                direction={0}
              >
                <FlexContainer
                  justify="flex-start"
                  align="center"
                  direction="row"
                  className={cnb("card-small")}
                >
                  <Flex
                    grow={10}
                    className={cnb("card-title", "action", "action-normal")}
                  >
                    {cardData?.name || product?.name}
                  </Flex>
                  <Flex
                    grow={0}
                    className={cnb("card-title", "paragraph", "paragraph-normal")}
                  >
                    {formik.values.amount}&nbsp;{cardData?.currency_code || product?.currency_code}
                  </Flex>
                </FlexContainer>
              </SlideWrapper>
              <dl className={cnb("card-summary", "paragraph", "paragraph-normal")}>
                <FlexContainer
                  className={cnb("card-summary-row")}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Payment method</dt><dd>{"Surf Pay"}</dd>
                </FlexContainer>
                <FlexContainer
                  className={cnb("card-summary-row")}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Gift Card</dt><dd>{updated ? priceOffer : <LoaderDotsText/>}&nbsp;{priceCurrency?.toUpperCase()}</dd>
                </FlexContainer>
                {/* <FlexContainer
                  className={cnb("card-summary-row")}
                  justify="space-between"
                >
                  <dt className={cnb("color-faded", "paragraph")}>Processing fee</dt><dd><LoaderDotsText/>&nbsp;EVER</dd>
                </FlexContainer> */}
              </dl>

              <Button
                className={cnb("card-page-cta")}
                type="button"
                variant="action"
                color="primary"
                size="large"
                iconRight={{
                  animation: "none",
                  icon: buttonCreateLoading ? <Loader className={cnb("button-loader")} /> : <div style={{flexGrow: 0, width: "auto"}}><span style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 55}}>{updated ? priceOffer : <LoaderDotsText/>}</span>&nbsp;{priceCurrency?.toUpperCase()}</div>
                }}
                onClick={handleCreateOrder}
              >Proceed to payment</Button>
            </Flex>
          </FlexContainer>
        </SwiperSlide>
      </Swiper>
       
    </FormikProvider>
        
  </Container></>
)};
export default Card;
