import { Dispatch, useEffect, useMemo, useState, useRef } from "react";

import axios, { AxiosResponse } from 'axios';
import { useRecoilState } from "recoil";
import { productAtom, productsAtom } from "../store";
import { useGifterClient } from './useGifterClient';


import {
  IGetPrice,
  IGetCheckout,
  IGetProduct,
  ICreateOrder,
  IGetPriceResponse,
  IGetProductResponse,
  IGetCheckoutResponse,
  ICreateOrderResponse,
  TProduct,
  TCountry,
  TCategory
 } from "../types";

export interface IUseCard {
  exchangeRate: number | undefined,
  priceCurrency: string | undefined,
  priceOffer: number | undefined,
  product: TProduct | undefined,
  similarProducts: Partial<TProduct>[],
  products: Partial<TProduct>[],
  getPrice: (params: IGetPrice, callback?: () => void) => void
  getCheckout: (params: IGetCheckout, callback?: () => void) => void
  createOrder: (params: ICreateOrder, callback?: () => void) => void
}

export const useCard = (params?: IGetProduct): IUseCard => {
  const GifterClient = useGifterClient();

  const [similarProducts, setSimilarProducts] = useState<Partial<TProduct>[]>([]);
  const [product, setProduct] = useRecoilState(productAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  useEffect(() => {
    GifterClient.getProduct(params)
      .then((value: IGetProductResponse) => {
        console.log(value);
        if (value) {
          setProduct({
            product: value.product,
            exchangeRate: value.exchangeRate,
            priceCurrency: value.priceCurrency,
            priceOffer: value.priceOffer,
          });
          setSimilarProducts(value.similarProducts);
        }
      });
    setProduct(undefined);
  }, [params?.code]);

  const getPrice = (params: IGetPrice, callback?: () => void) => {
    GifterClient.getPrice(params)
    .then((value: IGetPriceResponse) => {
      console.log(value);
      if (value && product) {
        setProduct({
          product: product.product,
          exchangeRate: value.exchangeRate,
          priceCurrency: product?.priceCurrency,
          priceOffer: value.priceOffer,
        });
        if (callback)
          callback();
      }
    })
  };
  const getCheckout = (params: IGetCheckout, callback?: () => void) => {
    GifterClient.getCheckout(params)
    .then((value: IGetCheckoutResponse) => {
      console.log(value);
      if (value && product) {
        setProduct({
          product: product.product,
          exchangeRate: value.exchangeRate,
          priceCurrency: product?.priceCurrency,
          priceOffer: value.priceOffer,
        });
        if (callback)
          callback();
      }
    })
  };
  const createOrder = (params: ICreateOrder, callback?: () => void) => {
    GifterClient.getCheckout(params)
    .then((value: ICreateOrderResponse) => {
      console.log(value);
      if (callback)
        callback();
      // if (value && product) {
      //   setProduct({
      //     product: product.product,
      //     exchangeRate: value.exchangeRate,
      //     priceCurrency: product?.priceCurrency,
      //     priceOffer: value.priceOffer,
      //   });
      //   if (callback)
      //     callback();
      // }
    })
  };

  useEffect(() => {
    console.log(params)
  }, [params?.code]);

  return useMemo(
    () => ({
      similarProducts: similarProducts,
      exchangeRate: product?.exchangeRate,
      priceCurrency: product?.priceCurrency,
      priceOffer: product?.priceOffer,
      product: product?.product,
      products,
      setProduct,
      getPrice,
      getCheckout,
      createOrder
    }),
    [similarProducts, product, products, getPrice],
  );
};
